<template>
  <div class="search">
    <el-form :inline="true"
             :model="form"
             class="demo-form-inline">
      <el-form-item label="姓名">
        <el-input v-model="form.user_name"
                  placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="选择日期">
        <el-date-picker v-model="form.date"
                        type="daterange"
                        format='YYYY-MM-DD'
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :disabled-date="disabledDate"
                        :shortcuts="shortcuts">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   class="search_button"
                   @click="searchForm">查询</el-button>
        <el-button class="search_button"
                   @click="clearForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { defineComponent, toRefs, reactive } from 'vue'

export default defineComponent({
  components: {
  },
  props: {

  },
  setup (props, context) {
    const data = reactive({ // 定义变量
      form: {
        user_name: '',
        date: ''
      },
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }, {
        text: '最近一个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })()
      }, {
        text: '最近三个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })()
      }],
      disabledDate (v) {
        return v.getTime() >= new Date().getTime()
      }
    })

    const searchForm = () => {
      context.emit('searchForm', data.form)
    }
    const clearForm = () => {
      data.form = {
        user_name: '',
        date: ''
      }
      context.emit('searchForm', data.form)
    }
    return {
      ...toRefs(data),
      clearForm,
      searchForm
    }
  }
})
</script>

<style scoped>
.search::v-deep(.el-form-item__label) {
  color: #333;
}
.search {
  padding-left: 20px;
}
.search_button {
  height: 36px !important;
  line-height: 10px !important;
  min-height: 36px;
}
</style>
